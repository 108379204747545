
























import { defineComponent, ref, watch } from '@vue/composition-api'
import { xRunningOrderExt } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { xRunningStartupOrderExt } from '@/models/UpwardRunning/Order/xRunningStartupOrderExt'
import OrderDetail from '@/components/UpwardRunning/OrderHistory/OrderDetail.vue'
import Loading from '@/elements/Loading.vue'
import { loadRunningOrder } from '@/lib/components/OrderDetail/loadOrder'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  name: 'OrderDetailPage',
  components: { Loading, OrderDetail },
  setup(props, ctx) {
    const currentOrder = ref<xRunningStartupOrderExt | xRunningOrderExt | null>(null)
    const currentOrderInfo = ref<OrderInfoExt | null>(null)
    const errorMessage = ref('')
    const loading = ref(false)
    watch(
      () => ctx.root.$route,
      () => getOrderByRoute(),
      { immediate: true }
    )

    async function getOrderByRoute() {
      errorMessage.value = ''
      const challenge = ctx.root.$route.params.id ?? ''
      const orderid = ctx.root.$route.params.orderid ?? ''
      try {
        loading.value = true
        await loadRunningOrder(challenge, orderid, currentOrder, currentOrderInfo, errorMessage)
      } catch (e) {
        const message = e.message || e.errorObject?.message
        errorMessage.value = `Problem loading the order detail, please contact support ${
          message ? `(${message})` : ''
        }
        `
        throw e
      } finally {
        loading.value = false
      }
    }
    return { currentOrder, loading, errorMessage, currentOrderInfo }
  },
})
